/* soundmachine.js file */

(function () {
    'use strict';

    var currentlyPlaying;

    var playSound = function (elem) {
        if (currentlyPlaying) {
            currentlyPlaying.stop();
        }
        elem.play();
        currentlyPlaying = elem;
    }

    var allSounds = document.querySelectorAll('.soundmachine-audio');
    console.log(sound.length);
    for (var sound in allSounds) {
        console.log(sound);
        // sound.addEventListener('click', function () {
        //     console.log('myname: ' + sound);
        // });
    }



})();
